import React from "react";
import Typical from "react-typical";
import "./infoBar.css";

function InfoBar() {
  const date = new Date();
  let greeting = "Hallo";

  if (date.getHours() <= 11) {
    greeting = "Guten Morgen";
  } else if (date.getHours() <= 18) {
    greeting = "Hallo";
  } else {
    greeting = "Guten Abend";
  }

  let currentDate = date.toLocaleString().substring(0, 10).replaceAll("/", ".");
  let currentTime = date.toLocaleString().substring(12, 17);

  return (
    <>
      <Typical
        steps={[
          "",
          1000,
          greeting + ", Nils!",
          2000,
          "",
          1000,
          "Heute ist der " + currentDate + ".",
          1500,
          "Es ist " + currentTime + " Uhr.",
        ]}
        loop={1}
        wrapper="p"
        className="greeting"
      />
    </>
  );
}

export default InfoBar;
