import React from "react";
import "./tile.css";

function Tile({ fav, title, url }) {
  return (
    <a href={url} style={{ textDecoration: "none" }}>
      <div className="content">
        <div className="tile">
          <img className="favicon" src={fav} alt="favicon" />
        </div>
        <span className="text">{title}</span>
      </div>
    </a>
  );
}

export default Tile;
