import InfoBar from "./components/InfoBar";
import "./App.css";
import Tile from "./components/Tile";
import data from "./data.json";

function App() {
  return (
    <>
      <InfoBar />
      <div className="container">
        <div className="grid">
          {data.map((site, idx) => {
            return (
              <Tile
                key={idx}
                fav={site.fav}
                title={site.title}
                url={site.url}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default App;
